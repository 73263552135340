import { MappedField, MappedFieldMapping } from 'Nbee'

export const isFieldMappingEmpty = (mapping: MappedFieldMapping): boolean =>
  !(mapping.sourceFieldId || mapping.text || mapping.formula?.id)

export const isFieldMapped = (field: MappedField): boolean =>
  field.mapping.some((m) => !isFieldMappingEmpty(m))

export const isFieldUnmapped = (field: MappedField): boolean =>
  !isFieldMapped(field)

export const getShowUnmappedInitialState = (
  fieldsMappingValues?: MappedField[]
): boolean => {
  // to understand if the initial state for showUnmapped is true or false, we need to check
  // if all required fields are already mapped.
  const requiredFields = (fieldsMappingValues || []).filter((f) => f.isRequired)

  // when we have no required fields
  if (!requiredFields.length) {
    const allAreUnmapped = (fieldsMappingValues || []).every(
      (f) => !isFieldMapped(f)
    )
    // if all fields are not mapped, we need to return true and show unmapped field as initial state
    // instead is something is mapped we return false so that we don't show unmapped field as initial state, but we only show the mapped ones
    return allAreUnmapped
  }

  // we proceed to check the case where we have required fields
  const allRequiredAreMapped = requiredFields.length
    ? requiredFields.every((f) => isFieldMapped(f))
    : false

  // if all required fields are already mapped we can hide the not mapped fields
  return !allRequiredAreMapped
}
