import { IntegrationFormFieldType } from 'Nbee'
import { ApiAppSettingTypes } from 'BackendApi'

export const fieldsForSmartSelect: IntegrationFormFieldType[] = ['dropdown']
export const fieldsForSmartCreatableSelect: ApiAppSettingTypes[] = ['tags']
export const fieldsForSmartVerifyEmail: ApiAppSettingTypes[] = [
  'validate_email',
  'validate_email_multi',
]
export const fieldsForDomain: IntegrationFormFieldType[] = [
  'domain',
  'subdomain',
]
export const fieldsForToggle: IntegrationFormFieldType[] = ['toggle']
export const fieldsForInput: IntegrationFormFieldType[] = [
  'text',
  'number',
  'password',
  'textarea',
  'datetime',
  'code',
  'email',
  'url',
]
