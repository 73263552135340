type PostMessageData = {
  isLbMessage: true
  integrationId: number
  integrationName: string
}

export const sendIntegrationDataToParentWindow = (
  newIntegrationId: number,
  newIntegrationName: string
) => {
  const parentWindow = window.opener
  if (!parentWindow) {
    return
  }

  const message: PostMessageData = {
    isLbMessage: true,
    integrationId: newIntegrationId,
    integrationName: newIntegrationName,
  }

  setTimeout(() => {
    // just be sure all segment events have been sent, before killing this window from the parent
    parentWindow.postMessage(message, '*')
  }, 300)
}
