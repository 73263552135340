import { ApiAppSetting, ApiAppSettingTypes } from 'BackendApi'
import { SelectValue } from '@components/Form/InputSmartSelect'
import {
  AppConfigurationType,
  AppIntegrationSetting,
  BridgeFormValues,
} from 'Nbee'
import FacebookService from '@app/services/FacebookService'
import { FormikErrors, FormikTouched } from 'formik'
import { InputFeedbackStatus } from '@components/Basic/InputFeedback'

/*
 * Grouping setting field types to render specific components
 * example if in future we need to handle type `datatime` with a component
 * that is not a simple input (maybe a datetime-picker) we can take it off
 * from the `fieldsForInput` array and move it into a new one that will be handle
 * inside the main `IntegrationSettings` component
 */
export const fieldsForGooglePicker: ApiAppSettingTypes[] = ['google_picker']

export const fieldsForSmartSelect: ApiAppSettingTypes[] = ['dropdown']
export const fieldsForSmartCreatableSelect: ApiAppSettingTypes[] = ['tags']
export const fieldsForToggle: ApiAppSettingTypes[] = ['toggle']
export const fieldsForInput: ApiAppSettingTypes[] = [
  'text',
  'number',
  'password',
  'domain',
  'textarea',
  'datetime',
  'code',
  'email',
  'url',
  'subdomain',
]

/*
 * Helper to transorm formik value into react-select format
 */
export const transformSettingDataInSelectOptions = (
  settingData?: { id: string | number; text: string }[]
): SelectValue[] =>
  (settingData || []).map(({ id, text }) => ({
    value: id,
    label: text,
  }))

export const getAppIntegrationSettingsIndex = (fieldId: string, settings: AppIntegrationSetting[]): number => {
  let index: number = 0
  for(const setting of settings) {
    if (setting.key === fieldId) {
      break
    }
    index++
  }
  return index
}

/*
 * Since setting fields are quite complex and we ar not using formik FieldArray
 * we need an helper to easly retrive error and touched state in order to properly
 * build our error feedback object of type `InputFeedbackStatus`
 */
export const getSettingFieldError = ({
  type,
  touched,
  errors,
  index,
}: {
  type: AppConfigurationType
  touched: FormikTouched<BridgeFormValues>
  errors: FormikErrors<BridgeFormValues>
  index: number
}): {
  isTouched: boolean
  errorMessage: string
  fieldStatus?: InputFeedbackStatus
} => {
  const touchedSettings = ((touched[type]?.settings &&
    touched[type]?.settings) ||
    []) as boolean[]
  const errorsSettings = ((errors && errors[type] && errors[type]?.settings) ||
    []) as unknown as string[]
  const isTouched = touchedSettings[index]
  const errorMessage = errorsSettings[index]

  return {
    isTouched,
    errorMessage,
    fieldStatus: errorMessage
      ? {
          error: errorMessage,
        }
      : undefined,
  }
}

/*
 * This function is used to handle the "create new form" option if setting field is "Facebook form"
 * `callback` parameter is used to update form state
 */
export const handleCreateNewFacebookForm = (
  integrationSettings: AppIntegrationSetting[],
  callback: (payload?: {
    formID: string
    // eslint-disable-next-line camelcase
    form_url: string
    pageID: string
    name: string
  }) => void
) => {
  if (!window.FB) {
    FacebookService.createScriptFb()
  }

  const pageIdSetting = integrationSettings.find((s) => s.key === 'pageId')
  const adAccountSetting = integrationSettings.find(
    (s) => s.key === 'advertiserAdAccountId'
  )

  const pageIdValue = pageIdSetting?.value
  const adAccountValue = adAccountSetting?.value as string

  if (!pageIdValue || !adAccountValue) {
    console.error('Missing adAccountValue or pageIdSetting')
    return
  }

  const fbUI = FB.ui as any

  fbUI(
    {
      method: 'lead_gen',
      page_id: pageIdValue,
      ad_account_id: adAccountValue.replace('act_', ''),
    },
    callback
  )
}

/*
 * Starting from setting fields returned from api, which are all empty,
 * we ne to return a new formik setting field state which include old values
 * for existing settings and default values for new ones
 */
export const fillNewSettingsWithExistingValues = (
  newAndEmptySettings: ApiAppSetting[],
  existingSettingsWithValues: AppIntegrationSetting[]
): AppIntegrationSetting[] =>
  newAndEmptySettings.map((newEmptySetting) => {
    const oldSetting = existingSettingsWithValues.find(
      ({ key }) => key === newEmptySetting.id
    )
    if (oldSetting) {
      return oldSetting
    }

    // we set `false` for type toggle and `0` for type number
    // everything else it's safe to keep it as empty string (also if is an array of string)
    // inputs will always handle it as empty value
    return {
      key: newEmptySetting.id,
      value:
        newEmptySetting.default || newEmptySetting.default === 0
          ? newEmptySetting.default // if we have a default value we set it
          : newEmptySetting.type === 'toggle'
          ? false
          : newEmptySetting.type === 'number'
          ? 0
          : '',
    }
  })
