import { BbuPartnersName } from '@app/enums/bridgeByUrl'

const invalidBbuUrlErrors: Record<
  BbuPartnersName,
  { message: string; link: string; linkText: string }
> = {
  [BbuPartnersName.facebookConversionLeads]: {
    message:
      'Your URL is not valid, please check again from App Store or contact support.',
    link: 'https://business.facebook.com/events_manager2/',
    linkText: 'Go back to Meta Events Manager',
  },
  [BbuPartnersName.facebook]: {
    message:
      'Your URL is not valid, please check again from App Store or contact support.',
    link: 'https://business.facebook.com/business/app-store/',
    linkText: 'Go back to Meta Business App Store',
  },
  [BbuPartnersName.tiktok]: {
    message:
      'Your URL is not valid, please check again from your TikTok for Business dashboard or contact support.',
    link: 'https://www.tiktok.com/business/it/apps/tiktok',
    linkText: 'Go back to TikTok',
  },
  [BbuPartnersName.app]: {
    message: 'Your URL is not valid, please contact support.',
    link: 'https://www.leadsbridge.com',
    linkText: 'Go back to LeadsBridge',
  },
}

// generic messages added as fallback, if partnerName is not one of the allowed partners you should not reach this error block
export const getInvalidBbuUrlErrors = (partnerName: BbuPartnersName) =>
  invalidBbuUrlErrors[partnerName] || {
    message: 'Your URL is not valid, please contact support.',
    link: 'https://leadsbridge.com/contact-us/',
    linkText: 'Contact support',
  }
