import { CognitoUserInterface } from '@aws-amplify/ui-components'
import { BridgeFormValues, AppConfigurationType } from 'Nbee'
import { matchPath } from 'react-router'
import { appRoutes } from '@app/routes'
import { TrackingStep } from 'DataTracking'

export const extractUserIdFromCognitoUser = (user?: CognitoUserInterface) => {
  const slaveUserId: string =
    user && user.attributes && user.attributes['custom:legacySlaveUserId']
      ? 'SUID-' + user.attributes['custom:legacySlaveUserId']
      : 'SUID-0'

  const mainUserId: string =
    user && user.attributes && user.attributes['custom:legacyUserId']
      ? 'UID-' + user.attributes['custom:legacyUserId']
      : 'UID-0'

  const userId = slaveUserId === 'SUID-0' ? mainUserId : slaveUserId
  const accountId: string =
    user?.attributes && user.attributes['custom:legacyUserId']

  return {
    userId,
    accountId,
  }
}

export const extractEmailFromCognitoUser = (
  user?: CognitoUserInterface
): {
  email: string | undefined
} => {
  const cognitoEmail: string | undefined =
    user && user.attributes && user.attributes.email
  // when doing social authentication we'll receive an email that contains `+EXTERNAL`, we need to strip that text out to avoid sending dups to segment
  const email = cognitoEmail?.replace('+EXTERNAL', '')

  return {
    email,
  }
}

export const makeNbeeTrackingParams = (
  formValues: BridgeFormValues,
  type?: AppConfigurationType
) => {
  // in case a type (source or destination) is passed, we can add more info
  const currentAppParams = type
    ? {
        appId: formValues[type].appId,
        appName: formValues.ui && formValues.ui[type]?.selectedAppName,
        integrationId: formValues[type].integrationId,
        integrationName:
          formValues.ui && formValues.ui[type]?.selectedIntegrationName,
      }
    : {}

  return {
    sourceAppId: formValues.source.appId,
    sourceAppName: formValues.ui?.source?.selectedAppName,
    destinationAppId: formValues.destination.appId,
    destinationAppName: formValues.ui?.destination?.selectedAppName,
    bridgeId: formValues.ui?.bridgeId,
    bridgeName: formValues.name,
    ...currentAppParams,
  }
}

export const makePricingTrackingStep = (
  locationPathname: string
): TrackingStep | undefined => {
  const isInPricing = matchPath(locationPathname, {
    path: appRoutes.pricing.path,
    exact: true,
  })
  const isInCheckout = matchPath(locationPathname, {
    path: appRoutes.checkout.path,
    exact: true,
  })

  return isInPricing ? 'Table' : isInCheckout ? 'Checkout' : undefined
}
