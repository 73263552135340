import { App } from 'Nbee'
import { SelectValue } from '@components/Form/InputSmartSelect'

export const tranformApiAppsToSelectOptions = (
  apiApps?: App[]
): SelectValue[] =>
  (apiApps || []).map((app) => ({
    value: `${app.id}`,
    parentAppId: app.parentAppId,
    label: app.name,
    logoUri: app.logoUriSmall,
  }))

export const getAppOptionFromAppId = (
  appId?: number,
  allOptions?: SelectValue[]
) => (allOptions || []).find((option) => option.value === `${appId}`)
